import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import VueYoutube from 'vue-youtube'
import { PasswordInput, NumberKeyboard, Toast, Cell, CellGroup, Collapse, CollapseItem, RadioGroup, Radio, Divider, Card } from 'vant';
import ElementUI from 'element-ui'
import clipboard from 'clipboard';

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import axios from 'axios'


import "@/assets/scss/app.scss";

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// import Vconsole from 'vconsole';
import  'element-ui/lib/theme-chalk/index.css'

// new Vconsole();

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}
Vue.prototype.$axios = axios;
Vue.prototype.clipboard = clipboard;

Vue.config.productionTip = false


Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Divider);
Vue.use(Card);

Vue.use(ElementUI)

Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.component('apexchart', VueApexCharts)


new Vue({
  router,
  store,
  i18n,
  data: function(){
    return {
        jurisdiction: [],
        ismobile: false,
    }
  },
  render: h => h(App)
}).$mount('#app')
