<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${this.$t("title")}` : this.$t("title");
    }
  },
  mounted() {
    var languagecode=navigator.language
    languagecode=languagecode.substr(0,2)
    
    // i18n.locale=languagecode

  }
};
</script>